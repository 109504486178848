import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UpdateUser, User } from 'app/modules/admin/users/admin-users.types';
import { AdminUsersService } from 'app/modules/admin/users/admin-users.service';
import { Site } from 'app/modules/admin/sites/admin-sites.types';
import { AdminSitesService } from 'app/modules/admin/sites/admin-sites.service';


@Component({
    selector: 'user-config',
    templateUrl: './user-config.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./user-config.component.scss'],
    standalone: false
})
export class UserConfigComponent implements OnInit, OnDestroy
{
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    settingsForm: UntypedFormGroup;
    loading = false;
    sites: Site[];
    selectedSiteIds: number[] = [];
    subscription: Subscription;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: UntypedFormBuilder,
        public dialogRef: MatDialogRef<UserConfigComponent>,
        @Inject(MAT_DIALOG_DATA) public data: User,
        private _adminUsersService: AdminUsersService,
        private _adminSitesService: AdminSitesService,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {            

        if (!this.data?.daysBeforeAlert) this.data.daysBeforeAlert = 4;
        if (!this.data?.daysBeforeDefectAlert) this.data.daysBeforeDefectAlert = 7;

        this.settingsForm = this._formBuilder.group({
            id: [this.data.userPoolUserId, Validators.required],
            daysBeforeAlert: [this.data.daysBeforeAlert, Validators.required],
            daysBeforeDefectAlert: [this.data.daysBeforeDefectAlert, Validators.required],
            alertsEnabled: [this.data.alertsEnabled, Validators.required],
            defectAlertsEnabled: [this.data.defectAlertsEnabled, Validators.required],
            alertSiteIds: this._formBuilder.array([])
        });

        // Get the sites
        this._adminSitesService.sites$.pipe(takeUntil(this._unsubscribeAll)).subscribe((sites: Site[]) => {

            // Update the sites
            this.sites = sites;
            this.selectedSiteIds = this.data.alertSites.map(site => site.id);

            var values = this.sites.map(site => this.selectedSiteIds.includes(site.id));
            this.settingsForm.controls['alertSiteIds'] = this._formBuilder.array(values);

            // Update selected sites on checkbox change
            const control = this.settingsForm.controls.alertSiteIds;
            this.subscription = control.valueChanges.subscribe(value => {
                this.selectedSiteIds = Object.keys(this.sites).map((id, index) =>
                    control.value[index] ? this.sites[id] : null)
                    .filter(id => !!id).map(site => site.id);
            });
            
            this._changeDetectorRef.markForCheck();
        });

        this._changeDetectorRef.markForCheck();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    enabledChange(checked) {
        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    daysBeforeAlertVisible() {
        let ctrl = this.settingsForm.get('alertsEnabled');
        if (!ctrl) return false;
        return ctrl.value;
    }

    daysBeforeDefectAlertVisible() {
        let ctrl = this.settingsForm.get('defectAlertsEnabled');
        if (!ctrl) return false;
        return ctrl.value;
    }

    onSave() {
        this.loading = true;
        var settings = {
            id: this.settingsForm.get('id').value,
            alertSiteIds: this.selectedSiteIds,
            daysBeforeAlert: this.settingsForm.get('daysBeforeAlert').value,
            daysBeforeDefectAlert: this.settingsForm.get('daysBeforeDefectAlert').value,
            alertsEnabled: this.settingsForm.get('alertsEnabled').value,
            defectAlertsEnabled: this.settingsForm.get('defectAlertsEnabled').value,
        } as UpdateUser;

        this._adminUsersService.updateUserSettings(settings).subscribe((user) => {
            this.loading = false;
            this.dialogRef.close(user);
        }, (error) => {
            this.loading = false;
            console.error(error);
            this.dialogRef.close(error);
        });
    }

}
