import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy {

    private readonly _destroying$ = new Subject<void>();
    
    /**
     * Constructor
     */
    constructor(private msalBroadcastService: MsalBroadcastService,
        private authService: MsalService,
        private _router: Router,
        private _activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
       

        this.msalBroadcastService.msalSubject$
            .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),)
            .subscribe((result: EventMessage) => {
                console.log('success!');

                const payload = result.payload as AuthenticationResult;
                this.authService.instance.setActiveAccount(payload.account);

                this.checkAndSetActiveAccount();
                let account = this.authService.instance.getActiveAccount();
                console.log('account ', account);

                const redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/signed-in-redirect';
                this._router.navigateByUrl(redirectURL);
            });


            this.msalBroadcastService.inProgress$.pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => this.checkAndSetActiveAccount())
    }

    ngOnDestroy(): void {
		this._destroying$.next(undefined);
		this._destroying$.complete();
	}

    checkAndSetActiveAccount() {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        let activeAccount = this.authService.instance.getActiveAccount();
        if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            let accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
        }
    }

}
